.navbar {
    background-color: #222;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin-right: 1rem;
  }
  
  .nav-link {
    color: #fff;
    padding: 0.5rem 1rem;
    text-decoration: none;
  }
  
  .nav-link:hover {
    background-color: #444;
  }
  
  .avatar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 1rem;
  }
  